import logo from './logo.svg';
import './styles/App.scss';
import {StoreProvider} from 'easy-peasy';
import {BrowserRouter as Router} from 'react-router-dom'
import {store} from './_store';
import AppRouter from "./_router";

function App() {
  return (
      <StoreProvider store={store} >
        <Router>
          <AppRouter/>
        </Router>
      </StoreProvider>
  );
}

export default App;

export const baseApiUrl = '';
export const loginPath = '';
export const sendPasswordRestPath = '';
export const validateAuthTokenPath = '';
export const validatePasswordResetPath = '';
export const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const virtualSizerUrl = 'https://api.boldmetrics.io/virtualsizer/get';
export const virtualSizerClient = process.env.REACT_APP_BOLDMETRICS_CLIENT;
export const virtualSizerKey = process.env.REACT_APP_BOLDMETRICS_API_KEY;
export const virtualSizerBrand = process.env.REACT_APP_BOLDMETRICS_BRAND;

export const recordSizeApiKey = process.env.REACT_APP_API_KEY;
export const recordSizeEndpoint = process.env.REACT_APP_API_ENDPOINT;


export const routes = {
	home:'/',
};

export const fieldTypes = {
	select:'select',
	number:'number',
	imageSelect:'image-select',
	text:'text'
}


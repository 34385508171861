import React from 'react';

export default function ImageSelect({field,options,changeHandler})
{

	return (
		<>
			<div className={'marked-input ' + ((field[1].error || !field[1].value) ? 'invalid' : 'valid')}>
				<div className={'marked-input value ' + (field[1].value ? '' : 'empty ')}>
					{field[1].value || field[1].placeholder}
				</div>
			</div>
			<div className={'options'}>
				{
					options && options.map((option, j) => {
						return (
							<div className={'image-option ' + (field[1].value === option.value ? 'selected' : '')}>
								<span>{option.label}</span>
								<img key={field[0] + 'op' + j} src={option.imgSrc} alt={option.value} onClick={() => {
									changeHandler(field[0], option.value);
								}}/>
							</div>
						)
					})
				}
			</div>
		</>
	)
}

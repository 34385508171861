export const getHttpErrorMessage = (error) =>{
	return (error.response && error.response.data && error.response.data.meta)?error.response.data.meta.message || error.message:error.message;
};
export const  getTextWidth = (text, font) => {
	const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
	const context = canvas.getContext("2d");
	context.font = font;
	return context.measureText(text).width;
};
export const appendScript = (scriptToAppend) => {
	const script = document.createElement("script");
	script.src = scriptToAppend;
	script.async = true;
	document.body.appendChild(script);
};

export const removeScript = (scriptToRemove) => {
	let allSuspects=document.getElementsByTagName("script");
	for (let i=allSuspects.length; i>=0; i--){
		if (allSuspects[i] && allSuspects[i].getAttribute("src")!==null
			&& allSuspects[i].getAttribute("src").indexOf(`${scriptToRemove}`)                !== -1 ){
			allSuspects[i].parentNode.removeChild(allSuspects[i])
		}
	}
};

export const uniqid = (length) => {
	let result           = [];
	let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let charactersLength = characters.length;

	for (let i = 0; i < length; i++) {
		result.push(
			characters.charAt(
				Math.floor(Math.random() * charactersLength)
			)
		);
	}

	return result.join('');
};

export const validateNumberFormat = (input,format,formatted=true) =>{
	if(formatted){
		if(!input){
			return false;
		}
		let valid = true;
		format.split('').forEach((char,index)=>{
			if(char === '#'){
				if(input.charAt(index) && isNaN(parseInt(input.charAt(index)))){
					valid = false;
				}
			}else{
				if(char !== input.charAt(index)){
					valid = false;
				}
			}
		});
		return valid;
	}else{
		if(!input || input.length<1){
			return false;
		}
		let formatDigits = format.match(/#/g);
		let inputDigits = input.match(/\d/g);

		if(!inputDigits){
			return false;
		}
		if(!formatDigits){
			return true;
		}
		return formatDigits.length === inputDigits.length;
	}
};

export const validateEmailAddress = (email) =>{
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const formatExternalLink = (link) => {
	if(/(http)/.test(link)){
		return link;
	}

	return 'http://' + link;
}

export const formatDate = (dateString) =>{
	dateString = dateString.replace(' ','T');
	if(dateString.indexOf('T')<0) {
		dateString+='T11:00:00';
	}
	let date = new Date(dateString);
	let year = date.getFullYear();

	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return month + '/' + day + '/' + year;
}

export const convertFeetToInches = (size) =>{
	if(!size){
		return '';
	}
	size = size.split('\'');

	let feet = parseInt(size[0].replace(/\D/g,''));
	let inches = 0;
	if(size[1]) {
		inches = parseInt(size[1].replace(/\D/g, ''));
	}
	return (feet*12)+inches;
}

import {action, createStore, persist} from "easy-peasy";

export const store = createStore({
	user:persist({
		anonId:'',
		user:{},
		email:'',
		displayName:'',
		token:'',
		isLoggedIn:false,
		setup:action((state,payload)=>{
			state.user = payload;
			state.displayName = payload.user_display_name;
			state.email = payload.user_email;
		}),
		updateToken:action((state,payload)=>{
			state.token = payload;
			state.isLoggedIn = !!payload;
			if(!payload){
				state.email = '';
				state.displayName = '';
			}
		}),
		setAnonId:action((state,payload)=>{
			state.anonId = payload;
		})
	}),
	alertMessage:{
		showMessage:false,
		type:'',
		message:'',
		setShowMessage:action((state,payload)=>{
			state.showMessage = payload;
		}),
		setMessage:action((state,payload)=>{
			state.message = payload?payload.message:'';
			state.type = payload?payload.type:'';
			state.showMessage = !!payload;
		})
	},
});

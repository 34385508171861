import React, {useState,useEffect} from 'react';
import { Switch, Route, Redirect, useHistory} from "react-router-dom";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from 'axios';
import {baseApiUrl, routes} from "./_constants";
import AlertMessage from "./components/alertMessage";
import {getHttpErrorMessage} from "./_helpers";
import LoginScreen from "./screens/loginScreen";
import AuthRequiredRoutes from "./_authRequiredRoutes";
import Header from "./components/header";
import SizerScreen from "./screens/sizerScreen";

export default function AppRouter()
{

	const [showMessage,setShowMessage] = useState(false);
	const isLoggedIn = useStoreState(state=>state.user.isLoggedIn);
	const authToken = useStoreState(state=>state.user.token);
	const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const message = useStoreState(state=>state.alertMessage.message);
	const setToken = useStoreActions(actions => actions.user.updateToken);
	const [messageTimer,setMessageTimer] = useState(null);
	const [showApp,setShowApp] = useState(false);

	useEffect(()=>{
		if(!message){
			clearTimeout(messageTimer);
			setShowMessage(false)
		}
		clearTimeout(messageTimer);
		setShowMessage(true);
		setMessageTimer(setTimeout(()=>{setShowMessage(false); setMessage(null)},5000));
	},[message]);

	axios.defaults.baseURL = baseApiUrl;
	useEffect(()=>{
		const requestInterceptor = axios.interceptors.request.use(
			function (config) {
				if(!isLoggedIn || !authToken){
					return config;
				}
				config.headers['Auth-token'] = authToken;

				return config;
			});
		const responseInterceptor = axios.interceptors.response.use(function(response){
			let message = (response.data && response.data.meta)?response.data.meta.message:null;
			if(message){
				setMessage({message: message});
			}
			return response;
		}, function(error){
			if(error.response && error.response.status === 401){
				setToken();
			}
			let message = getHttpErrorMessage(error);
			if(error.response && error.response.data && error.response.data.message && error.response.data.message.specifics){

				if(error.response.data.message.specifics.length>0){
					console.log(error.response.data.message.specifics[0].message)
					message = error.response.data.message.specifics[0].message
				}
			}
			if(message) {
				setMessage({message: message, type: 'error'});
			}
			return Promise.reject(error);
		});
		setShowApp(true);
		return ()=>{
			axios.interceptors.request.eject(requestInterceptor);
			axios.interceptors.request.eject(responseInterceptor);
		}
	},[authToken, isLoggedIn]);

	return (
		<div className={'app'}>
			<Header />
			<div className={'app-content max-width-container'}>
			{showApp &&
			<>
				{(!!message && showMessage) && <AlertMessage/> }
				<Switch >
					{/*<Route exact path='/'>*/}
					{/*	{isLoggedIn ? <Redirect to={routes.home} /> : <LoginScreen/>}*/}
					{/*</Route>*/}
					{/*<Route path='/'>*/}
					{/*	{(isLoggedIn || true)  ? <AuthRequiredRoutes/> : <Redirect to={'/'}/>}*/}
					{/*</Route>*/}
					<Route path={routes.home}>
						<SizerScreen />
					</Route>
				</Switch>
			</>
			}
			</div>
		</div>
	);
}

import React, {useState,useEffect} from 'react';

export default function StyledSelect({options,changeHandler,inputDisabled,field}){

	const [showOptions,setShowOptions] = useState(false);
	const [selectedOption,setSelectedOption] = useState(null);
	const [allOptions,setAllOptions] = useState([]);
	const [updated,setUpdated] = useState('');
	const clickHandler = (e) =>{
		if(inputDisabled){
			return;
		}
		if(e.target.hasAttribute('data-index')){
			let selected = allOptions[parseInt(e.target.getAttribute('data-index'))];
			setSelectedOption(selected);
			if(field) {
				changeHandler(field[0],selected.value);
			}else{
				changeHandler(selected.value);
			}
			setShowOptions(false);
			setUpdated(Math.random());
		}else{
			setShowOptions(!showOptions);
		}
	};
	const closeOptions = () =>{
		setShowOptions(false)
	}

	useEffect(()=>{
		let selected = null;
		setAllOptions(options.map(option=>{
			if(!option){
				return null;
			}
			if(!option.hasOwnProperty('selected')) {
				option.selected = false;
			}else if(option.selected){
				selected = option;
			}
			return option;
		}));
		if(!selectedOption && selected) {
			setSelectedOption(selected);
			if(field) {
				changeHandler(field[0],selected.value);
			}else{
				changeHandler(selected.value);
			}
		}
		setUpdated(Math.random());
	},[options]);

	return (
		<>

			{!!options &&

			<div className={'styled-selector marked-input ' + (inputDisabled?'disabled ':'') + (field[1].error || !field[1].value?'invalid ':'valid') + (!field[1].value?'empty ':'') } onClick={(e)=>clickHandler(e)}>
				<span className={'single '} >{selectedOption?selectedOption.label : field[1].placeholder}</span>
				{showOptions &&
				<div className='option-list single'>
					{allOptions.length > 0 && allOptions.map((option, index) => {
						return (
							<span key={option.label + option.value}
							      data-index={index}
							      className={(selectedOption && option.value === selectedOption.value ? 'selected ' : '')}
							>
                                            {option.label}
                                        </span>
						)
					})}
				</div>
				}
			</div>
			}
			{showOptions && <div onClick={(e)=>{clickHandler(e)}} className='click-catcher'/>}
		</>
	)
}

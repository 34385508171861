import React, {useEffect} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";

export default function AlertMessage()
{
	const msTimeToStayOpen = 3000;
	const setShowMessage = useStoreActions(actions => actions.alertMessage.setShowMessage);
	const message = useStoreState(state=>state.alertMessage?state.alertMessage.message:null);
	const type = useStoreState(state=>state.alertMessage?state.alertMessage.type:'');

	return(
		<div>
		{message &&
		<div className={'popup-message ' + type}>
			<p>{message}</p>
		</div>

		}
		</div>
	)
}


import React from 'react';
import logo from '../img/logo@2x.png'
export default function Header(){

	return (
		<div className={'header'}>
			<div className={'max-width-container'}>
				<img src={logo} className={'logo'}  alt={'Firedex'}/>
			</div>
		</div>
	)
}
